import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Toast } from './toat';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('Ignore-Interceptor')) {
      const headers = request.headers.delete('Ignore-Interceptor');
      const clonedRequest = request.clone({ headers });
      return next.handle(clonedRequest);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        const error = err.error || err.statusText;

        if (err.status === 0 || err.statusText === 'Unknown Error') {
          this.router.navigateByUrl('/auth/login');
          this.authenticationService.logout();
          Toast.warning('Something happened, valid and try again');
        } else if (err.status === 401) {
          Toast.warning('Your session has expired, please log in again');
          this.authenticationService.logout();

          this.router.navigateByUrl('/auth/login');
        } else if (err.status === 400) {
          Toast.warning(error);
        } else if (err.status === 429) {
          Toast.error(
            'Su cuenta ha sido bloqueada por exceder el número de intentos permitidos, intente más tarde'
          );
          this.authenticationService.logout();
          this.router.navigateByUrl('/auth/login');
        } else {
          Toast.error(error);
        }
        return throwError(error);
      })
    );
  }
}
