import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Toast } from '@app/core/helper/toat';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityComponent {
  private inactivityTime = 3 * 60 * 60 * 1000;
  private inactivityTimer: any;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private ngZone: NgZone
  ) {
    this.resetTimer();
    this.setupListeners();
  }

  private setupListeners() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', () => this.resetTimer());
      window.addEventListener('keypress', () => this.resetTimer());
      window.addEventListener('scroll', () => this.resetTimer(), true);
    });
  }

  private resetTimer() {
    clearTimeout(this.inactivityTimer);
    this.inactivityTimer = setTimeout(() => this.logout(), this.inactivityTime);
  }

  private logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
    Toast.error('Su sesión ha expirado por inactividad, por favor inicie sesión nuevamente.');
  }
}